import React, { useState, useEffect } from "react";
import { NotificationContainer, NotificationManager } from 'react-notifications';

export default function ContactForm() {
    const initialValues = { name: "", surname: "", email: "", phone: "", company: "", message: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);

        if (Object.keys(formErrors).length === 0 && isSubmit) {
            try {
                let res = await fetch("https://kintisoft.kintierp.com/api/crm/interested/", {
                    headers: {
                        "Content-type": "application/json",
                        "x-api-key": "0UFzYyVD.k8cwbjtowM5x8JaH3XP2JWQdLXmsJXsW"
                    },
                    method: "POST",
                    body: JSON.stringify({
                        product: 'KINTISOFT',
                        name: formValues.name,
                        surname: formValues.surname,
                        chanel: 'Página web',
                        company: formValues.company,
                        phone: formValues.phone,
                        accept_ads: true,
                        email: formValues.email,
                        message: formValues.message,
                    }),
                });
                let resJson = await res.json();
                if (res.status === 201) {
                    setFormValues(initialValues);
                    setFormErrors({});
                    NotificationManager.success('Nuestro equipo se contactará contigo', 'Correcto');
                } else {
                    NotificationManager.error('No se pudo enviar la información', 'Error', 5000, () => {
                        alert('callback');
                    });
                }
            } catch (err) {
                console.log(err);
            }
        }
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            console.log(formValues);
        }
    }, [formErrors]);

    const validate = (values) => {
        const formErrors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!values.name) {
            formErrors.name = "El nombre es obligatorio!";
        }
        if (!values.surname) {
            formErrors.surname = "El apellido es obligatorio!";
        }
        if (!values.email) {
            formErrors.email = "El correo es obligatorio!";
        } else if (!regex.test(values.email)) {
            formErrors.email = "Este no es un formato de correo electrónico válido!";
        }
        if (!values.phone) {
            formErrors.phone = "El teléfono es obligatorio!";
        }
        if (!values.company) {
            formErrors.company = "La empresa es obligatoria!";
        }
        if (!values.message) {
            formErrors.message = "El mensaje es obligatorio!";
        }
        return formErrors;
    };

    return (
        <>
            <form className="row" onSubmit={handleSubmit}>
                <div className="mb-3 col-12 col-md-6 col-xl-6">
                    <input
                        type="text"
                        name="name"
                        className={`form-control ${formErrors.name ? 'is-invalid' : ''}`}
                        placeholder="Nombre"
                        value={formValues.name}
                        onChange={handleChange}
                    />
                    {formErrors.name ? (
                        <div className="invalid-feedback">
                            {formErrors.name}
                        </div>
                    ) : null}
                </div>
                <div className="mb-3 col-12 col-md-6 col-xl-6">
                    <input
                        type="text"
                        name="surname"
                        className={`form-control ${formErrors.surname ? 'is-invalid' : ''}`}
                        placeholder="Apellido"
                        value={formValues.surname}
                        onChange={handleChange}
                    />
                    {formErrors.surname ? (
                        <div className="invalid-feedback">
                            {formErrors.surname}
                        </div>
                    ) : null}
                </div>
                <div className="mb-3 col-12 col-md-12 col-xl-12">
                    <input
                        type="text"
                        name="email"
                        className={`form-control ${formErrors.email ? 'is-invalid' : ''}`}
                        placeholder="Correo electrónico"
                        value={formValues.email}
                        onChange={handleChange}
                    />
                    {formErrors.email ? (
                        <div className="invalid-feedback">
                            {formErrors.email}
                        </div>
                    ) : null}
                </div>

                <div className="mb-3 col-12 col-md-12 col-xl-12">
                    <input
                        type="text"
                        name="phone"
                        className={`form-control ${formErrors.phone ? 'is-invalid' : ''}`}
                        placeholder="Teléfono celular"
                        value={formValues.phone}
                        onChange={handleChange}
                    />
                    {formErrors.phone ? (
                        <div className="invalid-feedback">
                            {formErrors.phone}
                        </div>
                    ) : null}
                </div>
                <div className="mb-3 col-12 col-md-12 col-xl-12">
                    <input
                        type="text"
                        name="company"
                        className={`form-control ${formErrors.company ? 'is-invalid' : ''}`}
                        placeholder="Empresa"
                        value={formValues.company}
                        onChange={handleChange}
                    />
                    {formErrors.company ? (
                        <div className="invalid-feedback">
                            {formErrors.company}
                        </div>
                    ) : null}
                </div>

                <div className="mb-3 col-12 col-md-12 col-xl-12">
                    <textarea
                        name="message"
                        className={`form-control ${formErrors.message ? 'is-invalid' : ''}`}
                        rows="5"
                        placeholder="¿Qué idea tienes en mente?"
                        value={formValues.message}
                        onChange={handleChange}
                    ></textarea>
                    {formErrors.message ? (
                        <div className="invalid-feedback">
                            {formErrors.message}
                        </div>
                    ) : null}
                </div>
                <div className="col-12 text-end">
                    <button
                        type="submit"
                        className="btn btn-primary px-5 btn-responsive"
                    >
                        Enviar
                    </button>
                </div>

            </form>
            <NotificationContainer />
        </>
    );
}