require('./bootstrap');

import React from 'react'
import { createRoot } from "react-dom/client";
import ContactForm from './components/ContactForm.jsx'

if (document.getElementById("footer-form")) {
  const component = createRoot(document.getElementById("footer-form"));
  component.render(<ContactForm />);
}

if (document.getElementById("contact-form")) {
  const component = createRoot(document.getElementById("contact-form"));
  component.render(<ContactForm />);
}


